<template>
  <div>
    <!-- Rate Limit -->
    <el-form-item v-if="lagInDesign"
      prop="portSpeed"
      label-width="280px"
      required
      :label="isMcr ? $t('services.rate-limit') : $t('ports.port-speed')">
      <el-select :value="form.portSpeed"
        class="min-width-350px"
        name="speed"
        data-testid="speed-select"
        data-name="port-speed"
        :placeholder="isMcr ? $t('services.select-rate-limit') : $t('ports.select-port-speed')"
        @change="handleFormInputChange({ value: $event }, 'portSpeed')">
        <el-option v-for="speed in speeds"
          :key="speed"
          :value="speed"
          :data-port-speed="speed"
          :disabled="(form.lagPort || portObj.aggregationId) && speed < 10000"
          :label="speedFix(speed)" />
      </el-select>
      <div v-if="isMcr && locationSelect.products.mcrVersion === 2"
        class="fine-print">
        {{ $t('ports.no-mcr-speed-change') }}
      </div>
    </el-form-item>
    <!-- END -->

    <!-- Name -->
    <el-form-item v-if="diversityObject.diversityOption !== G_DIVERSITY_ZONE_PAIR"
      :label="portTypeName"
      prop="productName"
      label-width="280px">
      <el-input :value="form.productName"
        data-name="port-name"
        :data-demo="serviceDemoName"
        data-testid="product-name-input"
        name="productName"
        @input="handleFormInputChange({ value: $event }, 'productName')" />
    </el-form-item>
    <!-- END -->

    <!-- This is a temporary message letting users know marketplace visibility settings have moved -->
    <!-- Megaport Marketplace Visibility -->
    <div class="el-form-item marketplace-form-item">
      <span class="el-form-item__label">
        {{ $t('productNames.marketplace') }}
      </span>
      <div class="disclaimer">
        <p class="m-0">
          {{ $t('marketplace.publish-disclaimer') }}
        </p>
      </div>
    </div>
    <!-- END -->

    <!-- Partner Deals -->
    <el-form-item v-if="showDeals"
      prop="deals"
      label-width="280px"
      :label="$t('general.partner-deals')">
      <el-select :value="form.dealUid"
        class="min-width-350px"
        @change="handleFormInputChange({ value: $event }, 'dealUid')">
        <el-option v-for="deal in deals"
          :key="deal.entityUid"
          :label="deal | formatDealName"
          :value="deal.entityUid" />
      </el-select>
    </el-form-item>
    <!-- END -->

    <!-- Invoice Reference -->
    <el-form-item prop="costCentre"
      label-width="280px">
      <template #label>
        {{ $t('services.invoice-reference') }}
        <el-tooltip placement="top"
          :open-delay="500">
          <template #content>
            <p class="tooltip-p">
              {{ $t('services.invoice-reference-explanation') }}
            </p>
            <p v-if="!disabledFeatures.knowledgeBase"
              class="tooltip-p">
              {{ $t('general.details-help') }}
              <a :href="isMcr ? 'https://docs.megaport.com/mcr/creating-mcr/' : 'https://docs.megaport.com/connections/creating-port/'"
                target="_blank">
                <el-button size="mini">{{ $t('general.documentation') }}</el-button>
              </a>
            </p>
          </template>
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input :value="form.costCentre"
        data-name="cost-centre"
        name="costCentre"
        @input="handleFormInputChange({ value: $event }, 'costCentre')" />
    </el-form-item>
    <!-- END -->

    <!-- Minimum Term -->
    <term-select id="intro-step-3"
      :value="form.term"
      :term-prices="portTermPrices"
      :loading-term-prices="loadingTermPrices"
      prop="term"
      :minimum-term="!isMcr"
      @input="handleFormInputChange({ value: $event }, 'term')" />

    <template v-if="isMcr">
      <!-- MCR ASN Note -->
      <i18n path="ports.uneditable-asn-html"
        tag="p"
        class="fine-print">
        <template #defaultAsn>
          <span>{{ MEGAPORT_ASN }}</span>
        </template>
        <template #cannotBeEdited>
          <strong>{{ $t('ports.cannot-edit') }}</strong>
        </template>
      </i18n>
      <!-- END -->

      <!-- MCR ASN -->
      <el-form-item prop="mcrAsn"
        :show-message="false">
        <mu-form-group :label="$t('ports.mcr-asn')"
          label-width="240px"
          label-align="right"
          required>
          <mu-asn-input :value="form.mcrAsn"
            data-testid="mcr-asn-input"
            required
            @update="handleInputChangeValidation($event, 'mcrAsn')" />
        </mu-form-group>
      </el-form-item>
      <!-- END -->

      <!-- BGP Default State -->
      <el-form-item prop="bgpShutdownDefault"
        label-width="280px">
        <template #label>
          {{ $t('ports.bgp-default-state') }}
          <el-tooltip placement="top"
            :content="$t('ports.bgp-default-state-tooltip')"
            :open-delay="500">
            <i class="fas fa-question-circle color-info padding0005"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-radio-group :value="form.bgpShutdownDefault"
          @input="handleFormInputChange({ value: $event }, 'bgpShutdownDefault')">
          <el-radio-button :label="false"
            data-testid="bgp-enabled-radio"
            name="bgpEnabled">
            {{ $t('general.enabled') }}
          </el-radio-button>
          <el-radio-button :label="true"
            data-testid="bgp-shutdown-radio"
            name="bgpShutDown">
            {{ $t('general.shut-down') }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <!-- END -->
    </template>

    <!-- Port Diversity -->
    <port-diversity-settings v-if="allowsPortDiversity"
      :initial-diversity-object="initialDiversityObject"
      :diversity-object="diversityObject"
      :is-lag="form.lagPort || !!portObj.aggregationId"
      :add-to-lag="portObj.aggregationId ? form.lagPortCount : 0"
      :location-id="form.locationSelectedId"
      :port-speed="form.portSpeed"
      @update="diversitySettingChanged" />
    <!-- END -->

    <!-- LACP & LAG Ports -->
    <div v-if="!isMcr"
      class="group-items">
      <div class="font-weight-500 text-align-center full-width mb-1">
        {{ $t('ports.lacp-ports') }}
        <el-tooltip placement="top"
          :content="$t('ports.lag-explanation')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon-end"
            aria-hidden="true" />
        </el-tooltip>
      </div>

      <div v-if="form.portSpeed < 10000"
        role="alert"
        class="disclaimer lag mt-1"
        data-name="no-lag-enable">
        {{ $t('ports.select-lag-speed') }}
      </div>

      <el-form-item v-if="!portObj.aggregationId"
        prop="lagPort"
        label-width="239px"
        :label="$t('ports.lacp-on-port')">
        <el-radio-group :value="form.lagPort"
          name="lagPort"
          data-name="lag-enable"
          :disabled="form.portSpeed < 10000"
          @input="handleFormInputChange({ value: $event }, 'lagPort')">
          <el-radio-button :label="true"
            data-testid="lag-enable-radio">
            {{ $t('billing-markets.enable') }}
          </el-radio-button>
          <el-radio-button :label="false"
            data-testid="lag-disable-radio">
            {{ $t('general.disable') }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-collapse-transition>
        <el-form-item v-if="(form.lagPort || portObj.aggregationId)"
          :label="$t(portObj.aggregationId ? 'ports.add-to-lag' : 'ports.ports-in-lag')"
          prop="lagPortCount"
          label-width="239px">
          <el-select :value="form.lagPortCount"
            data-testid="lag-port-count-select"
            data-name="lag-port-count"
            @change="handleFormInputChange({ value: $event }, 'lagPortCount')">
            <el-option v-for="n in availableLagSlots"
              :key="n"
              :value="n"
              :data-testid="`lag-${n}-option`"
              :data-name="'lag-' + n" />
          </el-select>
        </el-form-item>
      </el-collapse-transition>

      <el-collapse-transition>
        <div v-if="form.lagPort && form.lagPortCount > 1"
          class="disclaimer">
          {{ $t('ports.add-lag-ports-disclaimer') }}
        </div>
      </el-collapse-transition>

      <div v-if="!lagInDesign"
        class="disclaimer">
        {{ $t('ports.live-lag-disclaimer') }}
      </div>
    </div>
    <!-- END -->

    <!-- Resource Tags -->
    <resource-tags :resource-tags="form.resourceTags"
      :label-width="isMcr ? '280px' : '270px'"
      :class="{'group-items': !isMcr}"
      @update="handleFormInputChange({ value: $event }, 'resourceTags')" />
    <!-- END -->

    <el-collapse-transition v-if="locationSelect && locationSelect.status === 'Deployment'">
      <p>
        <strong>{{ $t('general.notice') }}:</strong> {{ $t(`ports.site-deployment`) }}
      </p>
    </el-collapse-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PortDiversitySettings from '@/components/ui-components/PortDiversitySettings.vue'
import TermSelect from '@/components/ui-components/TermSelect.vue'
import ResourceTags from '@/components/ui-components/ResourceTags.vue'
import { MEGAPORT_ASN, DEFAULT_TERM_PRICES } from '@/Globals.js'
import { scopedPriceBook } from '@/utils/priceBook.js'

export default {
  name: 'CreatePortDetails',

  components: {
    'port-diversity-settings': PortDiversitySettings,
    'term-select': TermSelect,
    'resource-tags': ResourceTags,
  },

  inject: ['isFeatureEnabled'],

  props: {
    portObj: {
      type: Object,
      required: true,
    },
    locationSelect: {
      type: Object,
      required: true,
    },
    lagInDesign: {
      type: Boolean,
      required: true,
    },
    speedFix: {
      type: Function,
      required: true,
    },
    speeds: {
      type: Array,
      required: true,
    },
    deals: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    disabledFeatures: {
      type: Object,
      required: true,
    },
    availableLagSlots: {
      type: Array,
      required: true,
    },
    allowsPortDiversity: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    initialDiversityObject: {
      type: Object,
      required: true,
    },
    diversityObject: {
      type: Object,
      required: true,
    },
    diversitySettingChanged: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      portTermPricesRaw: DEFAULT_TERM_PRICES,
      loadingTermPrices: false,
      MEGAPORT_ASN,
    }
  },

  computed: {
    ...mapGetters('Auth', ['isManagedAccount', 'isPartnerVantage']),

    portTypeName() {
      const portType = this.$t(this.isMcr ? 'productNames.mcr' : 'productNames.port')

      return this.$t('general.type-name', { type: portType })
    },
    showDeals() {
      // Hide if the vantage partner & vantage managed account flags aren't present
      if (!this.isFeatureEnabled('VANTAGE_PARTNER') && !this.isFeatureEnabled('VANTAGE_MANAGED_ACCOUNT')) {
        return false
      }

      // Show if managed account
      return this.isManagedAccount
    },
    isMcr() {
      return this.portObj.productType === this.G_PRODUCT_TYPE_MCR2
    },
    serviceDemoName() {
      const { name: locationName, metro } = this.locationSelect
      const { productType } = this.portObj

      return `${locationName} @ ${metro} - ${productType}`
    },
    portTermPrices() {
      const hasTermPrices = Object.values(this.portTermPricesRaw).some(price => price != null)

      if (hasTermPrices && !this.isMcr) {
        // LAG & DV pair price calculation happens locally (thankfully it's simple)
        let multiplier = 1
        if (this.form.lagPort || this.portObj.aggregationId) {
          multiplier = this.form.lagPortCount
        }
        if (this.allowsPortDiversity && this.diversityObject.diversityOption === this.G_DIVERSITY_ZONE_PAIR) {
          multiplier *= 2
        }

        if (multiplier !== 1) {
          return Object.entries(this.portTermPricesRaw)
            .reduce((portTermPrices, [term, price]) => {
              portTermPrices[term] = {
                ...price,
                monthlyRate: price.monthlyRate * multiplier,
              }
              return portTermPrices
            }, {})
        }
        // If the multiplier is 1, we don't need to do anything
      }
      return this.portTermPricesRaw
    },
  },

  watch: {
    // Update the portTermPricesRaw whenever the speed is changed. We use a watcher so we can support fetching the prices async
    'form.portSpeed'() {
      this.updateTermPrices()
    },
  },

  created() {
    this.updateTermPrices()
  },

  methods: {
    scopedPriceBook,

    handleFormInputChange({ value }, key) {
      this.$emit('update', { key, value })
    },
    handleInputChangeValidation({ value, valid, error }, key) {
      this.handleFormInputChange({ value }, key)

      this.$emit('validate', { key, valid, error })
    },
    async updateTermPrices() {
      if (!this.form.portSpeed) {
        this.portTermPricesRaw = DEFAULT_TERM_PRICES
      } else {
        this.loadingTermPrices = true

        try {
          this.portTermPricesRaw = this.isMcr
            ? await this.scopedPriceBook()
              .mcrTerms(
                this.locationSelect.id,
                this.form.portSpeed,
                this.portObj.productUid
              )
            : await this.scopedPriceBook()
              .megaportTerms(
                this.locationSelect.id,
                this.form.portSpeed,
                this.portObj.buyoutPort,
                this.portObj.productUid
              )
        } catch (error) {
          // Error handled by the pricebook module.
          this.portTermPricesRaw = DEFAULT_TERM_PRICES
        } finally {
          this.loadingTermPrices = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-form-group {
  margin-bottom: 1.4rem;
}
.fine-print {
  max-width: fit-content;
  font-size: 1.3rem;
  line-height: 1.375em;
  font-weight: 300;
  color: var(--color-text-regular);
}
.disclaimer {
  background-color: var(--color-white);
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-text-regular);
  margin-bottom: 1.3rem;
  &.lag {
    border: 1px solid var(--color-info);
  }
}
.group-items{
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  padding: 1rem 1.4rem 0 1rem;
  margin-bottom: 1rem;
  color: var(--color-text-primary);
}

// Only used for the temporary marketplace migration message
.marketplace-form-item {
  .el-form-item__label {
    width: 280px;
  }
  .disclaimer {
    margin-left: 280px;

    p {
      max-width: 400px;
    }
  }
}
</style>
